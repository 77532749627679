<template>
  <div class="grouping">
    <el-card class="card">
      <div class="titleSearch">
        <el-form ref="formInline" :inline="true" :model="formInline">
          <el-form-item prop="title">
            <el-input v-model="formInline.title" placeholder="请输入分组标题" clearable></el-input>
          </el-form-item>
        </el-form>
        <el-button type="primary" class="search" @click="search">查找</el-button>
      </div>
    </el-card>
    <div class="header">
      <div class="title">视频资源分组管理</div>
      <div class="back" @click="back">
        <div><img src="../../../assets/course/back.png" alt="" /></div>
        <div>返回</div>
      </div>
    </div>
    <div class="content">
      <el-table
        :data="list"
        class="table"
        :header-cell-style="tableHeaderCellStyle"
        @cell-mouse-enter="mouseEnterTable"
        @cell-mouse-leave="mouseLeaveTable"
      >
        <el-table-column prop="groupName" label="分组名称">
          <template slot-scope="scope">
            <el-input
              v-if="scope.row.editStatus"
              v-model="scope.row.groupName"
              @blur="editBlur(scope.row, scope.$index)"
            ></el-input>
            <div v-else>{{ scope.row.groupName }}</div>
          </template>
        </el-table-column>
        <el-table-column fixed="right" width="100px">
          <template slot="header">
            <span class="icon" @click="addGroupItem"><i class="el-icon-plus"/></span>
          </template>
          <template slot-scope="scope">
            <div v-if="scope.row.flag" class="operation">
              <span class="edit" @click="edit(scope.row)">
                <img src="../../../assets/course/newEdit.png" alt="" />
              </span>
              <span class="del" @click="deleteItem(scope.row)">
                <img src="../../../assets/course/del.png" alt="" />
              </span>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog :visible.sync="centerDialogVisible" :show-close="false" width="480px" class="dialog">
      <div class="title"><i class="el-icon-warning"></i>确认要删除这条信息吗？</div>
      <div class="text">分组下的视频关联会解除，确定要删除所选分类吗？</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitDel">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { groupList, delGroupList, addGroup, editGroup } from '@/api/course'
import to from 'await-to'
export default {
  data() {
    return {
      loading: false,
      formInline: {},
      list: [],
      centerDialogVisible: false,
      delId: '',
    }
  },
  created() {
    this.groupListData()
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    mouseEnterTable(row) {
      row.flag = true
    },
    mouseLeaveTable(row) {
      row.flag = false
    },
    async editBlur(row, index) {
      if (row.groupName !== '') {
        row.editStatus = false
        if (!row.groupId) {
          const [, err] = await to(addGroup({ groupName: row.groupName, groupId: row.groupId }))
          if (err) {
            this.$message.warning(err.msg)
            console.log(index)
            this.list.splice(index, 1)
          } else {
            this.$message.success('添加成功')
            window.location.reload()
          }
        } else {
          const [, err] = await to(editGroup({ groupName: row.groupName, groupId: row.groupId }))
          if (err) return this.$message.warning(err.msg)
          this.$message.success('编辑成功')
        }
      } else {
        this.$message.warning('分组名称不能为空')
      }
    },
    tableHeaderCellStyle() {
      return 'background:#eaeaea !important'
    },
    async groupListData() {
      //曾灿就是nb
      this.loading = true
      const [res, err] = await to(groupList({ groupName: this.formInline.title }))
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.list = res.data
      this.list.map(v => {
        this.$set(v, 'flag', false)
        this.$set(v, 'editStatus', false)
        this.$set(v, 'addStatus', false)
      })
    },
    search() {
      if (this.formInline.title.length > 6) {
        this.$message.warning('字数不能超过46个字')
      } else {
        this.groupListData()
      }
    },
    addGroupItem() {
      this.list.unshift({
        groupName: '新建分组',
        editStatus: true,
      })
    },
    edit(row) {
      row.editStatus = true
    },
    deleteItem(row) {
      this.centerDialogVisible = true
      this.delId = row.groupId
    },
    async submitDel() {
      const [, err] = await to(delGroupList({ groupId: this.delId }))
      if (err) return this.$message.warning(err.msg)
      this.$message.success('删除成功')
      this.centerDialogVisible = false
      this.groupListData()
    },
  },
}
</script>

<style scoped lang="scss">
.grouping {
  .header {
    display: flex;
    align-items: center;
    margin-top: 27px;
    margin-bottom: 17px;
    .title {
      font-size: 18px;
      font-weight: 500;
      color: #4d4e5d;
    }
    .back {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 72px;
      height: 26px;
      border: 1px solid #d9d9d9;
      border-radius: 4px;
      color: #606266;
      font-size: 14px;
      margin-left: 18px;
      cursor: pointer;
      img {
        width: 15px;
        height: 14px;
        margin-right: 3px;
      }
    }
  }

  .card {
    ::v-deep .el-card__body {
      padding: 0;
    }
    padding: 22px 0 22px 20px;
    .titleSearch {
      display: flex;
      ::v-deep .el-input {
        width: 194px;
      }
      ::v-deep .el-form-item {
        margin-bottom: 0;
      }
      .search {
        margin-left: 10px;
      }
    }
  }

  .content {
    ::v-deep .el-table thead tr :nth-child(1) .cell,
    ::v-deep .el-table tbody tr :nth-child(1) .cell {
      padding-left: 52px;
    }
    ::v-deep .el-table tbody tr {
      background-color: #fff;
    }
    ::v-deep .el-table::before,
    ::v-deep .el-table__fixed-right::before {
      height: 0 !important;
    }
    ::v-deep .el-table tbody td {
      border: none;
    }
    .table {
      .icon {
        cursor: pointer;
        font-size: 20px;
        color: #ff7b33;
        padding-left: 30px;
        i {
          font-weight: bold;
        }
      }
      .operation {
        display: flex;
        .edit {
          cursor: pointer;
          display: flex;
          align-items: center;
          img {
            width: 18px;
            height: 18px;
          }
        }
        .del {
          cursor: pointer;
          display: flex;
          align-items: center;
          margin-left: 15px;
          img {
            width: 13px;
            height: 18px;
          }
        }
      }
    }
  }
  .dialog {
    ::v-deep .el-dialog__header {
      padding: 0;
    }
    ::v-deep .el-dialog__footer {
      .el-button {
        width: 64px;
        height: 32px;
      }
    }
    .title {
      display: flex;
      align-items: center;
      font-size: 16px;
      margin-bottom: 14px;
      i {
        color: #e6a23c;
        font-size: 24px;
        margin-right: 16px;
      }
    }
    .text {
      padding-left: 40px;
      font-size: 14px;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.65);
      width: 376px;
    }
  }
}
</style>
