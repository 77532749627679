<template>
  <div class="course-shop-collect">
    <table-list
      title="我的收藏夹"
      :loading="loading"
      :data="collectList"
      :columns="columns(this)"
      :operates="operates(this)"
      :pager="pager"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    ></table-list>
  </div>
</template>
<script>
// 表格列配置
const columns = () => [
  {
    prop: 'name',
    label: '课程信息',
    render: (h, { row }) => {
      return [
        h('div', { style: 'display:flex' }, [
          row.pic
            ? h('img', {
                domProps: { src: row.pic },
                style: 'height: 78px;width:137px;margin-right:7px',
              })
            : null,
          h('div', [
            h('p', { style: 'margin-bottom:30px;display:flex;align-item:center' }, [
              h('span', { style: 'margin-right:20px' }, row.name),
              h('img', {
                domProps: {
                  src: require('@/assets/shop/lubo_icon.png'),
                  style: 'height: 22px;width:56px',
                },
              }),
            ]),
            h('p', { style: 'color:#979797' }, row.chapterCount + '节'),
          ]),
        ]),
      ]
    },
  },
  {
    prop: 'quantityPrice',
    label: '按学员人数量',
    formatter: row => {
      return row.quantityPrice + '元'
    },
  },
  {
    prop: 'buyoutPrice',
    label: '按包年不限量',
    formatter: row => {
      return row.buyoutPrice + '元'
    },
  },
]
// 表格操作按钮配置
const operates = _this => [
  {
    label: '删除',
    method: _this.handleDelete,
  },
]
import TableList from '@/components/TableList'
import { courseShopList } from '@/api/class'
import { collectCourse } from '@/api/course'
import to from 'await-to'
export default {
  name: 'CourseShopCollect',
  components: {
    TableList,
  },
  data() {
    return {
      columns,
      operates,
      loading: false,
      collectList: [],
      selectStudents: [],
      deptList: [],
      classList: [],
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
      queryParams: {},
    }
  },
  mounted() {
    this.getCollectCourse()
  },
  methods: {
    async getCollectCourse() {
      if (this.loading) return
      const { current, size } = this.pager
      this.loading = true
      const [res, err] = await to(
        courseShopList({ byMyCollection: 0, current, size, ...this.queryParams }),
      )
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.collectList = res.data.list
      this.pager.total = res.data.total
    },
    // 删除
    async handleDelete({ productId }) {
      // if (!this.selectStudents.length) return this.$message.warning('请先选择要删除的课程资源')
      // let resources = []
      // this.selectStudents.map(item => {
      //   resources.push({ id: item.id, materialType: item.materialType })
      // })
      this.$confirm('确认移除收藏吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        const [, err] = await to(collectCourse({ productCourseId: productId, collectionType: 1 }))
        if (err) return this.$message.warning(err.msg)
        this.$message.success('删除成功')
        this.getCollectCourse()
      })
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.getCollectCourse()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.getCollectCourse()
    },
  },
}
</script>
